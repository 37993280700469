<template>
  <div>
    <div class="flex justify-between items-center">
      <VTitle :title="$t('app.logs', 2)" class="section-title" />

      <VButton
        icon="fa-file-download fa-lg"
        class="btn-primary"
        @click="onClickDownload"
      />
    </div>

    <TableCard
      class="col-span-4"
      :rows="displayedRows"
      :headers="headers"
      :is-loading="isLoading"
      :pagination="pagination"
      display-pagination
      @update:current_page="onUpdateCurrentPage"
    >
      <!-- eslint-disable-next-line -->
      <template #item.action="{ item }">
        <VChip
          :text="item.displayed_action"
          :class="getActionColor(item.action)"
        />
      </template>
    </TableCard>
  </div>
</template>

<script>
import { computed, onMounted, inject } from "vue";
import { useI18n } from "vue-i18n";
import { useStore } from "@/store";
// Composables
import useReadMultiple from "@/composables/useReadMultiple";
import usePaginate from "@/composables/usePaginate";
import useColor from "@/composables/useColor";
import useDownload from "@/composables/useDownload";
// Components
import TableCard from "@/components/tables/TableCard";
import VChip from "@/components/VChip";
import VTitle from "@/components/VTitle";
import VButton from "@/components/VButton";

export default {
  components: {
    TableCard,
    VChip,
    VTitle,
    VButton
  },
  props: {
    id: {
      type: [String, Number],
      default: ""
    },
    logType: {
      type: String,
      required: true
    }
  },
  setup(props) {
    // MISC
    const { t } = useI18n();
    const store = useStore();
    const moment = inject("moment");

    // Composables
    const { pagination, data, isLoading, read } = useReadMultiple();
    const { getActionColor } = useColor();
    const { download } = useDownload();

    // Constants
    const headers = computed(() => {
      return [
        {
          text: t("app.date"),
          value: "date"
        },
        {
          text: t("app.time"),
          value: "time"
        },
        {
          text: t("app.users", 1),
          value: "user"
        },
        {
          text: t("app.ip_address"),
          value: "ip"
        },
        {
          text: t("app.action"),
          value: "action"
        }
      ];
    });

    // Computed
    const dateFormat = computed(() => store.getters["app/date_format"]);

    const displayedRows = computed(() => {
      return data.value.map(row => ({
        ...row,
        date: moment(row.logged_at).format(dateFormat.value),
        time: moment(row.logged_at).format("HH:mm:ss"),
        displayed_action: t(`backend.${row.action}`)
      }));
    });

    // METHODS
    const getData = async () => {
      await read({
        endpoint: "administration.logs.resource",
        pathParams: {
          log_type: props.logType,
          resource_id: props.id
        }
      });
    };

    const onClickDownload = () => {
      download("administration.logs.resource", {
        log_type: props.logType,
        resource_id: props.id
      });
    };

    // Lifecycle Hooks
    onMounted(async () => {
      await getData();
    });

    const { onUpdateCurrentPage } = usePaginate(pagination, getData);

    return {
      headers,
      displayedRows,
      onClickDownload,
      // useReadMultiple
      isLoading,
      pagination,
      // usePaginate
      onUpdateCurrentPage,
      // useColor
      getActionColor
    };
  }
};
</script>
